import React from "react";
import styled from "styled-components";
import { navigate, Link } from "gatsby";
import P1 from "./typography/P1";
import P2 from "./typography/P2";
import H2 from "./typography/H2";
import Button from "./ui/Button";
import PostAuthor from "./PostAuthor";

const PostPreviewWrapper = styled.div`
  margin-bottom: 3em;
`;

const PostPreview = ({ post }) => {
  return (
    <PostPreviewWrapper>
      <H2 huge gradient>
        <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
      </H2>
      <PostAuthor frontmatter={post.frontmatter} />
      <P1 serif>{post.excerpt}</P1>
      <Button
        color="dark"
        small
        onClick={() => {
          navigate(post.frontmatter.path);
        }}
        style={{ marginTop: ".7em" }}
      >
        Keep reading...
      </Button>
    </PostPreviewWrapper>
  );
};

export default PostPreview;
