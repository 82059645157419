import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import IconSubscribe from "../../assets/icons/mail.svg";
import IconRss from "../../assets/icons/rss.svg";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import PostPreview from "../components/PostPreview";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import H1 from "../components/typography/H1";
import Button from "../components/ui/Button";
import "../main.css";

const Subheader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7em 2.9em 0;
  width: 100%;
  @media (max-width: 640px) {
    padding-right: 1.8em;
    padding-left: 1.8em;
    a {
      padding: 0.5em 0.7em;
      p {
        font-size: 0;
        svg {
          font-size: 1rem;
          margin: 0;
        }
      }
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: 1em;
  }
`;

const BlogPage = props => {
  const {
    data: {
      allMarkdownRemark: { edges }
    }
  } = props;

  const Posts = edges
    .filter(edge => {
      const frontMatter = edge.node.frontmatter;

      return (
        frontMatter.path.includes("blog") &&
        // 👇 don't include in the shown posts unlisted posts (subpages of longer blog posts like the WLDM gmail guide)
        frontMatter.listed === true
      );
    })
    .map(edge => <PostPreview key={edge.node.id} post={edge.node} />);

  return (
    <>
      <SEO
        title="Boxy Suite Blog - Thoughts on Email and Productivity"
        description="In our official blog, we write about indie making and our development process. We also cover topics ranging from productivity to email security."
        canonical={props.location.href}
      />
      <SiteHeader dark />
      <Subheader>
        <H1 color="grey" style={{ margin: 0, fontSize: "1.4em" }}>
          Boxy Suite Blog
        </H1>
        <Buttons>
          <Button href="/rss.xml" color="accent2" small>
            <IconRss />
            RSS
          </Button>
          <Button to="/newsletter" color="accent1" small>
            <IconSubscribe />
            Subscribe
          </Button>
        </Buttons>
      </Subheader>
      <CookieBanner />
      <LayoutWrapper>
        <LayoutSection small>{Posts}</LayoutSection>
      </LayoutWrapper>
      <Footer />
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
            listed
          }
        }
      }
    }
  }
`;
